import React from "react";

import Layout from "../../components/layout";
import {GettingStartedSidebar as Sidebar} from "../../components/sidebar";
import {GettingStartedBreadcrumb as Breadcrumb} from "../../components/breadcrumb";
import {AskHowToInfoAlert, Callout} from "../../components/alert";
import {ApiLink} from "../../components/link";
import {KeyHeader} from "../../components/typography";

const pageTitle = "Transactions";

const Page = () => (
    <Layout pageTitle={pageTitle}
            sidebarNav={<Sidebar/>}
            mobileNav={<Breadcrumb activeName={pageTitle}/>}
    >
        <p>
            A{' '}<em>transaction</em>{' '}is a unit of work that is done as a
            single operation. There are two base characteristics of transaction:
        </p>
        <ul>
            <li><a href="#Transaction_mode">Transaction mode</a></li>
            <li><a href="#Transaction_Isolation_Level">Transaction Isolation Level</a></li>
        </ul>

        <KeyHeader id="Transaction_mode">Transaction mode</KeyHeader>
        <p>
            Transactions can be completed by either being{' '}<em>committed</em>{' '}
            or being{' '}<em>rolled back</em>. When a transaction is committed, the
            changes made in that transaction are made permanent. When a transaction
            is rolled back, the affected rows are returned to the state they were
            in before the transaction was started.
        </p>
        <p>
            Transactions can be in one of two modes:{' '}<strong>auto-commit</strong>{' '}
            mode or{' '}<strong>manual-commit</strong>{' '}mode.
        </p>
        <p>
            <em>In auto-commit mode</em>, every database operation is a
            transaction that is committed when performed. In databases without
            transaction support, auto-commit mode is the only supported mode. In
            such databases, statements are committed when they are executed and
            there is no way to roll them back; they are therefore always in
            auto-commit mode.
        </p>
        <p>
            <em>In manual-commit mode</em>, applications must explicitly
            complete transactions by calling
            {' '}<ApiLink>SAConnection::Commit</ApiLink>{' '}
            to commit them or {' '}<ApiLink>SAConnection::Rollback</ApiLink>{' '}
            to roll them back. This is the normal transaction mode for most relational databases.
        </p>
        <Callout heading="Note">
            The default transaction settings are DBMS-defined.
        </Callout>
        <p>
            Using SQLAPI++ to set auto-commit mode compulsory you should
            call {' '}<ApiLink>SAConnection::setAutoCommit</ApiLink>{' '}
            method with parameter value {' '}<strong>SA_AutoCommitOn</strong>. To set
            manual-commit mode you should call
            {' '}<ApiLink>SAConnection::setAutoCommit</ApiLink>{' '}
            method with parameter value {' '}<strong>SA_AutoCommitOff</strong>. If you
            didn't set transaction mode explicitly then the library uses the
            default DBMS transaction settings. See DBMS documentation to get
            information concerning the default transaction mode.
        </p>
        <p>
            If you disconnected from server without commit, then the
            result of transaction depends on underlying DBMS. It can either being
            committed or being rolled back. See DBMS documentation.
        </p>
        <p>
            Calling {' '}<ApiLink>SAConnection::Commit</ApiLink>{' '}
            or {' '}<ApiLink>SAConnection::Rollback</ApiLink>{' '}
            in auto-commit is safe (it has no effect). If you are working in
            manual-commit mode and call {' '}<ApiLink>SAConnection::Commit</ApiLink>{' '}
            method then current transaction is committed and new one is started. If
            you are working in manual-commit mode and call
            {' '}<ApiLink>SAConnection::Rollback</ApiLink>{' '}
            method then current transaction is rolled back and new one is started.
        </p>

        <KeyHeader id="Transaction_Isolation_Level">Transaction Isolation Level</KeyHeader>
        <p>
            {' '}<em>Transaction isolation</em>{' '} refers to the degree of
            interaction between multiple concurrent transactions. SQL-92 defines
            four isolation levels, all of which are supported by SQLAPI++:
        </p>
        <ul>
            <li>
                Read uncommitted (the lowest level where transactions are
                isolated just enough to ensure that physically corrupt data is not read)
            </li>
            <li>Read committed</li>
            <li>Repeatable read</li>
            <li>
                Serializable (the highest level, where transactions are completely isolated from one another)
            </li>
        </ul>
        <p>
            SQLAPI++ maps different isolation levels on underlying DBMS.
            You can set transaction isolation level explicitly by calling
            {' '}<ApiLink>SAConnection::setIsolationLevel</ApiLink>.
        </p>
        <Callout heading="Note">
            If you change isolation level it causes implicit
            commit for this connection.
        </Callout>
        <p>
            If you didn't set transaction isolation level explicitly then
            the library uses the default DBMS transaction settings. See DBMS
            documentation to get information concerning the default transaction
            isolation level.
        </p>

        <AskHowToInfoAlert/>
    </Layout>
);

export default Page;
